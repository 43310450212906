<template>
  <div id="container">
    <section id="mypage">
      <div class="wrap">
        <!-- 塾生情報の編集 -->
        <div class="cts_box">
          <h2 class="sub_ttl"><span>塾生情報の編集</span></h2>
          <p class="lead_txt">編集したい項目に入力して「編集内容の保存」ボタンを押してください。</p>
          <div class="student_info flex mb40">
            <div class="stdt_icon">
              <h3>プロフィール画像</h3>
              <img v-bind:src="current_user.profile_image_url" id="profile-image" alt="">
              <div class="btn_wrap">
                <label>
                  <div class="btn photo_select"></div>
                  <input type="file" accept="image/png,image/jpeg" id="picture_image"
                         style="display: none" v-on:change="profileImageSelected($event)"/>
                </label>
                <div class="btn photo_delete" v-on:click="profileImageDeleted()"></div>
              </div>
            </div>
            <div class="student_preset_img_list">
              <h3>プリセット画像（クリックすると左のプロフィール画像に反映されます）</h3>
              <ul class="flex">
                <li v-for="preset_id in max_preset" :key="preset_id">
                  <a href="javascript:void(0);" v-on:click="presetSelected(preset_id)">
                    <img v-bind:src="presetImagePath(preset_id)" v-bind:id="'preset-' + preset_id" alt=""></a>
                </li>
              </ul>
            </div>
          </div>

          <div class="sns_nickname">
            <h3>ユーザーネーム（表示名）<span>*</span></h3>
            <p>
              複眼経済塾のWEBサイト上で他の塾生に表示されるお名前です。変更したい場合はこちらに入力してください。
            </p>
            <div class="input_set">
              <div class="input_fields">
                <div class="field">
                  <input type="text" class="nickname" v-bind:class="{error: validation_errors.humhub_username}"
                         @input="checkUpdate()"
                         v-model="current_user.humhub_username">
                  <ValidationErrors :errors="validation_errors.humhub_username"
                                    v-if="validation_errors.humhub_username"></ValidationErrors>
                </div>
              </div>
            </div>
          </div>

          <div class="button_wrap">
            <p>編集が完了しましたらこちらのボタンを押して編集内容を保存してください。</p>
            <a href="javascript:void(0);" v-bind:class="{disabled: !valid}" v-on:click="update()" class="submit large light arrow">編集内容の保存</a>
          </div>

          <div class="button_wrap">
            <router-link :to="{name: 'UsersCustomerAccount'}" class="submit large light skeleton arrow back">
              マイページに戻る
            </router-link>
          </div>
        </div>

      </div>
    </section>

  </div>
</template>

<script>
import Libraries from '@/components/Libraries'
import ValidationErrors from '@/components/ValidationErrors'
import {sprintf} from "sprintf-js";

export default {
  mixins: [Libraries],
  components: {
    ValidationErrors,
  },
  data() {
    return {
      max_preset: 30,
      errors: false,
      validation_errors: {},
      valid: true
    }
  },
  created() {
    this.current_user.humhub_username = this.current_user.sns_user_name
    this.current_user.profile_image_preset_id = null
    this.checkUpdate()
  },
  methods: {
    checkUpdate() {
      this.valid = this.current_user.humhub_username.length > 0
    },
    profileImageDeleted() {
      this.current_user.profile_image_url = `${this.env.api_base_url}presets/empty.png`
      this.current_user.remove_profile_image = true
    },
    profileImageSelected() {
      const params = new FormData();
      const fileSelectDom = document.getElementById('picture_image')
      params.append('file', fileSelectDom.files[0])
      //params.append('token', this.$route.params.token)
      this.validation_errors.profile_image = null
      this.current_user.profile_image_cache = null
      //this.current_user.profile_image_url = null
      this.current_user.remove_profile_image = false
      this.axios
          .post(`${this.env.api_base_url}users/picture.json`, params)
          .then(response => {
            this.current_user.profile_image_cache = response.data.cache_name
            this.current_user.profile_image_url = response.data.url
          })
          .catch(error => {
            if (error.response.data && error.response.data.errors) {
              // console.log(error.response.data);
              this.validation_errors.profile_image = error.response.data.errors
            }
          });
    },
    presetImagePath(preset_id) {
      return sprintf(`${this.env.api_base_url}presets/%02d.png`, preset_id)
    },
    presetSelected(preset_id) {
      this.current_user.profile_image_url = this.presetImagePath(preset_id)
      this.current_user.remove_profile_image = false
      this.current_user.profile_image_preset_id = preset_id

      /*
      this.current_user.remove_profile_image = false
      this.axios
          .post(`${this.env.api_base_url}users/picture_preset.json`, {
            preset_id: preset_id
          })
          .then(response => {
            //console.log(response.data)
            this.current_user.profile_image_cache = response.data.cache_name
            this.current_user.profile_image_url = response.data.url
          })
          .catch(error => {
            if (error.response.data && error.response.data.errors) {
              console.log(error.response.data);
              this.validation_errors.profile_image = error.response.data.errors
            }
          });
       */
    },
    update() {
      this.startAjax()
      this.axios
          .post(`${this.env.api_base_url}users/update.json`, {
            user: this.current_user,
          })
          .then(response => {
            //console.log(response.data)
            this.saveSession(response.data.user);
            this.$router.push({name: 'UsersCustomerAccount'})
          })
          .catch(error => {
            this.errors = true
            if (error.response.data) {
              if (error.response.data.errors) {
                this.errors = true
                this.scrollTo('#form-top')
              }
              if (error.response.data.validation_errors) {
                this.validation_errors = error.response.data.validation_errors
              }
            }
          })
          .finally(() => {
            this.finishAjax()
            // alert('更新しました');
          })
    },
  }
}
</script>
